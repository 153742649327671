.headerWrapper {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  width: 102.2%;
  margin-left: -0.9rem;
  margin-top: -1rem;
}
.divider {
  border-left: 1px solid #797777;
}
.logo {
  width: 3rem;
  object-fit: contain;
  margin-right: 8px;
  cursor: pointer;
  @media screen and (min-width: 768px) {
    width: 10rem;
  }
}
.admin {
  color: #c20000;
}
.btnImg {
  width: 0.5rem;
  height: 30px;
  @media screen and (min-width: 768px) {
    width: auto;
  }
}
.avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  object-fit: cover;
}
.playbackSection {
  min-width: 24rem;
}

.navLink {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected {
  color: rgb(255, 255, 255);

  svg {
    fill: white;
  }
}
.custom-active {
  svg {
    fill: white;
  }
}

.textcolor {
  color: "black";
}

@media screen and (max-width: "900px") {
  .btn {
    width: 90px;
    height: 35px;
    margin-right: 0 !important;
    .navLink {
      font-size: 8px;
      .icon {
        height: 20px !important;
        width: 20px !important;
      }
    }
  }
}
