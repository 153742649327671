.videoCard {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;

  .videoPreview {
    width: 12rem;
    height: 9rem;
    position: relative;

    .videoPreviewOverlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
    }

    .preview {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .play {
      width: 1.2rem;
    }
  }

  .check {
    padding: 0.3rem;
    display: flex;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
  }
  .videoCardBoxWidth {
    width: 12rem;
  }
}
